import React from 'react';
import { Trans } from 'react-i18next';

import { Flex, Link, Text } from '@chakra-ui/react';

import { Modal } from '~/components/modal';
import { useI18n } from '~/hooks/use-i18n';

interface SubscriptionPausedModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentDate?: string | null;
}

export function SubscriptionPausedModal(props: SubscriptionPausedModalProps) {
  const { t } = useI18n();

  const { isOpen, onClose, paymentDate } = props;

  return (
    <Modal
      footerButtons={<Link href='/settings/subscription'>{t('settings.manageSubscription')}</Link>}
      isOpen={isOpen}
      title={t('settings.subscription.isPaused')}
      onClose={onClose}
    >
      <Flex direction='column' gap={4}>
        <Text>{t('settings.subscription.pauseBlockedContent1')}</Text>
        <Text>{t('settings.subscription.pauseBlockedContent2')}</Text>
        <Text>
          <Trans
            i18nKey='settings.subscription.resumeSubscriptionDate'
            transSupportBasicHtmlNodes={true}
            values={{
              resumeDate: paymentDate,
            }}
          />
        </Text>
      </Flex>
    </Modal>
  );
}
