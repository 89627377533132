import { z } from 'zod';

import { gql } from '~/utils/gql';

export const allowBlockListSchema = z.object({
  enableRegexpMatching: z.boolean().nullable(),
  allowList: z.array(z.string()).nullable(),
  blockList: z.array(z.string()).nullable(),
});

export const allowBlockListFragment = gql`
  fragment allowBlockList on AllowBlockList {
    enableRegexpMatching
    allowList
    blockList
  }
`;
