import { z } from 'zod';

export const favoritesSchema = z
  .object({
    favorites: z.array(z.string()),
  })
  .transform((data) => data.favorites);

export type FavoritesResponse = z.input<typeof favoritesSchema>;
export type Favorites = z.infer<typeof favoritesSchema>;
export type Favorite = Favorites[number];
