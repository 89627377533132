import { useCallback } from 'react';

import { matcher } from '~/utils/matcher';

export function useAllowBlockList(
  config:
    | {
        blockList?: string[] | null;
        allowList?: string[] | null;
        enableRegexpMatching?: boolean | null;
      }
    | null
    | undefined
) {
  const { blockList, allowList, enableRegexpMatching = false } = config || {};
  const findMatch = useCallback(
    (target: string, patterns: string[]) =>
      matcher(target, patterns, { enableRegexpMatching: Boolean(enableRegexpMatching) }),
    [enableRegexpMatching]
  );
  const targetLookUp = useCallback(
    (target: string) => {
      if (blockList && allowList) {
        return findMatch(target, allowList) && !findMatch(target, blockList);
      }

      if (blockList) {
        return !findMatch(target, blockList);
      }

      if (allowList) {
        return findMatch(target, allowList);
      }
      // Note: otherwise, no allow/block list is defined, so we allow the target.
      return true;
    },
    [allowList, blockList, findMatch]
  );
  return { targetLookUp };
}
