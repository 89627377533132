import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClientRender } from '~/hooks/use-client-render';

import { useUser } from '../../user/hooks/use-user';
import { deleteFavorite } from '../controllers/delete-favorite';
import { getFavorites } from '../controllers/get-favorites';
import { postFavorite } from '../controllers/post-favorite';

export function useFavorites() {
  const { userId } = useUser();
  const clientReady = useClientRender();
  const queryClient = useQueryClient();

  const {
    data: favorites,
    isLoading: favoritesIsLoading,
    isFetched: favoritesIsFetched,
  } = useQuery({
    queryKey: 'favorites',
    queryFn: () => getFavorites(userId!),
    enabled: clientReady && Boolean(userId),
  });

  const addFavorite = useMutation({
    mutationFn: ({ userId, programKey }: { userId: string; programKey: string }) => postFavorite(userId, programKey),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'favorites' });
    },
  });

  const removeFavorite = useMutation({
    mutationFn: ({ userId, programKey }: { userId: string; programKey: string }) => deleteFavorite(userId, programKey),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'favorites' });
    },
  });

  return {
    favorites,
    favoritesIsLoading,
    favoritesIsFetched,
    addFavorite,
    removeFavorite,
  };
}
