import Link from 'next/link';

import { Flex, Text } from '@chakra-ui/react';
import { CaretRightIcon } from '@udacity/chakra-uds-icons';

import { useI18n } from '~/hooks/use-i18n';

export type PlayButtonWithOnClick = {
  onClick: () => void;
  href?: never;
  active?: boolean;
  overrideColor?: string;
  ariaLabel?: string;
};

export type PlayButtonWithLink = {
  onClick?: never;
  href: string;
  active?: boolean;
  overrideColor?: string;
  ariaLabel?: string;
};

export type PlayButtonWithNoAction = {
  onClick?: never;
  href?: never;
  active?: boolean;
  overrideColor?: string;
  ariaLabel?: never;
};

type PlayButtonProps = PlayButtonWithOnClick | PlayButtonWithLink | PlayButtonWithNoAction;

export function PlayButton(props: PlayButtonProps) {
  const { t } = useI18n();
  const isPlayButtonWithLink = isPlayButtonWithLinkProps(props);
  const isPlayButtonWithOnClick = isPlayButtonWithOnClickProps(props);

  function getComponentAs() {
    if (isPlayButtonWithLink) {
      return Link;
    } else if (isPlayButtonWithOnClick) {
      return 'button';
    } else {
      return 'div';
    }
  }

  return (
    <Flex
      _hover={{ shadow: 'md' }}
      alignItems='center'
      as={getComponentAs()}
      backgroundColor='white'
      border={props.active ? 'solid 1px' : ''}
      borderColor='gray.300'
      rounded='full'
      width='fit-content'
      {...(isPlayButtonWithOnClick && { onClick: props.onClick, 'aria-label': props.ariaLabel })}
      {...(isPlayButtonWithLink && { href: props.href, 'aria-label': props.ariaLabel })}
    >
      <Flex
        alignItems='center'
        border='solid 2px'
        borderColor={props.overrideColor || 'accent.sea-foam.500'}
        borderRadius='full'
        height='40px'
        justifyContent='center'
        overflow='hidden'
        width='40px'
      >
        <CaretRightIcon color={props.overrideColor || 'blue.500'} h='44px' ml='4px' w='44px' />
      </Flex>
      {props.active && (
        <Text paddingEnd='16px' paddingStart='8px' size='label'>
          {t('program.resume')}
        </Text>
      )}
    </Flex>
  );
}

function isPlayButtonWithLinkProps(props: PlayButtonProps): props is PlayButtonWithLink {
  return 'href' in props;
}

function isPlayButtonWithOnClickProps(props: PlayButtonProps): props is PlayButtonWithOnClick {
  return 'onClick' in props;
}
