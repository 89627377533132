import { useMemo } from 'react';

import { Flex, keyframes } from '@chakra-ui/react';
import { StarEmptyIcon, StarFullIcon, StarHalfIcon } from '@udacity/chakra-uds-icons';

const animationKeyframes = keyframes`
  0% { opacity: .3 }
  50% { opacity: 1}
  100% { opacity: .3 }
`;

const numberOfStars = 5;

export function Rating({
  rating,
  color = 'accent.lime',
  size = 'md',
  isLoading = false,
}: {
  rating?: number;
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
}) {
  const starSize = useMemo(() => {
    if (size === 'lg') return '48px';
    if (size === 'md') return '32px';
    if (size === 'sm') return '20px';
  }, [size]);

  const stars = useMemo(() => {
    const renderedStars = [];

    const starStyles = {
      color: isLoading || rating === 0 ? 'gray.300' : color,
      height: starSize,
      width: starSize,
    };

    for (let i = 0; i < numberOfStars; i++) {
      const difference = (rating ?? 5) - i;
      if (difference <= 0) renderedStars.push(<StarEmptyIcon key={i} {...starStyles} />);
      else if (difference < 1) renderedStars.push(<StarHalfIcon key={i} {...starStyles} />);
      else renderedStars.push(<StarFullIcon key={i} {...starStyles} />);
    }

    return renderedStars;
  }, [color, isLoading, rating, starSize]);

  return (
    <Flex
      animation={isLoading ? `${animationKeyframes} 2s ease-in-out infinite` : 'none'}
      aria-label={`Rated ${rating} out of ${numberOfStars}`}
      role='img'
    >
      {stars}
    </Flex>
  );
}
