import auth from '@udacity/ureact-hoth';

import { publicEnv } from '~/features/environment/public';
import { logger } from '~/features/logging/logger';
import { queryUdacityService } from '~/utils/query-udacity-service';

export async function postFavorite(userId: string, programKey: string): Promise<any> {
  const jwt = auth.getJWT();

  if (!userId || !jwt) {
    logger.fatal('User is not authenticated', {
      file: 'src/features/favorites/controllers/post-favorite.ts',
      function: 'postFavorite',
      error: {
        userId,
      },
    });

    throw new Error('User is not authenticated');
  }

  return await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_RECOMMENDATIONS_URL}/favorites?userId=${userId}`,
    method: 'POST',
    zodSchema: null,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      programKey,
    }),
  });
}
