import { publicEnv } from '~/features/environment/public';
import { logger } from '~/features/logging/logger';
import { getUserIdFromJwt } from '~/features/user/utils/get-user-id-from-jwt';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { Enrollments, enrollmentsSchema, fetchEnrollmentsQuery } from '../models/program-enrollment';

export async function getEnrollments(jwt: string): Promise<Enrollments> {
  const userId = getUserIdFromJwt(jwt);

  if (!userId) {
    logger.fatal('User is not authenticated', {
      file: 'src/features/program/controllers/get-enrollments.ts',
      function: 'getEnrollments',
      error: {
        userId,
      },
    });

    throw new Error('User is not authenticated');
  }

  return await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_CLASSROOM_CONTENT_URL,
    zodSchema: enrollmentsSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: fetchEnrollmentsQuery,
      variables: { userId },
    }),
  });
}
