import { publicEnv } from '../environment/public';

import { getDefaultAnalyticProperties, getGeoAnalyticsProperties } from './utils/get-analytics-properties';
import { AnalyticsEvent } from './types';

const isEventLoggerOn =
  publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && publicEnv.NEXT_PUBLIC_DEBUG_ANALYTICS;

export function trackEvent(
  analyticsEvent: AnalyticsEvent,
  { sendToSalesforce = false }: { sendToSalesforce?: boolean } = {},
  callback?: () => void
) {
  if (typeof window === 'undefined' || !window.analytics) return;

  const { name, additionalAttributes, ...remainingEventProperties } = analyticsEvent;

  if (isEventLoggerOn) {
    console.log(`${analyticsEvent?.type}:`, analyticsEvent);
  }

  return window.analytics.track(
    name,
    {
      ...getDefaultAnalyticProperties(),
      ...getGeoAnalyticsProperties(),
      ...remainingEventProperties,
      ...additionalAttributes,
    },
    {
      integrations: {
        Salesforce: sendToSalesforce,
      },
    },
    callback
  );
}
