import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { kebabCase } from 'lodash-es';

import { useDisclosure } from '@chakra-ui/react';

import { BrowBanner } from '~/components/brow-banner';
import { setClientCookie } from '~/features/cookies/utils/set-client-cookie';
import { usePromotion } from '~/features/promotions/hooks/use-promotion';
import { useAllowBlockList } from '~/hooks/use-allow-block-list';

export function PromotionBanner() {
  const { isLoading, data } = usePromotion();
  const { asPath } = useRouter();
  const banner = data?.banner;
  const dismissalCookieKey = banner?.dismissalCookieKey ?? kebabCase(`${data?.promotionId}-banner-dismissed`);
  const hasDismissed = dismissalCookieKey && Cookies.get(dismissalCookieKey) === 'true';

  const { targetLookUp: isRouteEnabled } = useAllowBlockList(banner?.routeRestrictions);

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: () => {
      const expirationDate = data?.endDateTime
        ? new Date(data.endDateTime)
        : new Date(Date.now() + 1000 * 60 * 60 * 24 * 7 * 2); // 2 weeks from today.
      if (dismissalCookieKey) {
        setClientCookie(dismissalCookieKey, 'true', expirationDate);
      }
    },
  });

  if (isLoading || !banner || hasDismissed || !isRouteEnabled(asPath)) {
    return null;
  }

  return (
    <BrowBanner
      isCloseable={banner.isCloseable}
      open={isOpen}
      promotionId={data?.promotionId}
      text={banner.text}
      type={banner.type}
      onClose={onClose}
    />
  );
}
