import { useMemo } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Stack,
  StyleProps,
} from '@chakra-ui/react';

import { Link } from '~/components/link';
import { useBaseUrl } from '~/hooks/use-base-url';

import { NavGroup, NavLink } from '../types';
import { flattenNavGroups, trackFooterLinkClick } from '../utils';

export function Navigation({ navigation }: { navigation: NavGroup[] }) {
  return (
    <>
      <Flex display={{ base: 'none', md: 'flex' }} gap='32px' justifyContent='space-between'>
        {navigation.map((navGroup) => (
          <NavigationGroup key={navGroup.title} navGroup={navGroup} />
        ))}
      </Flex>
      <Box display={{ base: 'block', md: 'none' }}>
        <MobileNavigation navigation={navigation} />
      </Box>
    </>
  );
}

export function NavigationGroup({ navGroup }: { navGroup: NavGroup }) {
  return (
    <Stack maxWidth='256px' spacing='40px'>
      <Stack spacing='16px'>
        <Heading as='h2' color='black' fontWeight='600' size='h4'>
          {navGroup.title}
        </Heading>
        <FooterNav gap='16px' groupTitle={navGroup.title} links={navGroup.links} />
      </Stack>
      {navGroup.nestedNavGroups?.map((nestedNavGroup) => (
        <NavigationGroup key={nestedNavGroup.title} navGroup={nestedNavGroup} />
      ))}
    </Stack>
  );
}

export function MobileNavigation({ navigation }: { navigation: NavGroup[] }) {
  const flattenedNavigation = useMemo(() => flattenNavGroups(navigation), [navigation]);

  return (
    <Accordion allowMultiple>
      {flattenedNavigation.map((navGroup) => (
        <AccordionItem key={navGroup.title}>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              {navGroup.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <FooterNav gap='24px' groupTitle={navGroup.title} links={navGroup.links} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export function FooterNav({ links, groupTitle, ...styleProps }: { links: NavLink[]; groupTitle: string } & StyleProps) {
  return (
    <Stack as='nav' {...styleProps} aria-label={`Navigation for ${groupTitle} related links.`}>
      {links.map((link) => (
        <FooterLink key={link.href} groupTitle={groupTitle} link={link} />
      ))}
    </Stack>
  );
}

export function FooterLink({
  link,
  groupTitle = 'Top level',
  ...styleProps
}: { link: NavLink; groupTitle?: string } & StyleProps) {
  const baseUrl = useBaseUrl();

  return (
    <Link
      _visited={{ color: 'black' }}
      color='black'
      flex='1'
      fontWeight='500'
      href={link.isAbsolute ? link.href : `${baseUrl}${link.href}`}
      isExternal={link.isExternal}
      locale={false}
      textDecoration='none'
      onClick={() => trackFooterLinkClick(link.label, groupTitle)}
      {...styleProps}
    >
      {link.label}
    </Link>
  );
}
