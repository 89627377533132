import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';

import { useFreeTrialEligibilityForAuthUser } from './use-free-trial-eligibility-for-auth-user';
import { useFreeTrialFeatureFlag } from './use-free-trial-feature-flag';

export type FreeTrialStates = { status: 'off' } | { status: 'on' } | { status: 'loading' };

export function useFreeTrialState(): FreeTrialStates {
  const clientReady = useClientRender();
  const { isUserAuthenticated } = useUser();
  const { enabled, isLoading: isFeatureFlagLoading } = useFreeTrialFeatureFlag();
  const { eligible, isLoading: isEligibilityLoading } = useFreeTrialEligibilityForAuthUser();

  if (isFeatureFlagLoading || isEligibilityLoading || !clientReady) {
    return { status: 'loading' };
  }

  if (!enabled || (isUserAuthenticated && !eligible)) {
    return { status: 'off' };
  }

  return { status: 'on' };
}
