import { useQuery } from 'react-query';

import { getEnabledFeatureFlagsCookie } from '~/features/cookies/utils/get-enabled-feature-flag-cookie';
import { publicEnv } from '~/features/environment/public';
import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';

import { FREE_TRIAL_FEATURE_FLAG_KEY } from '../constants';
import { getFreeTrialEligibilityForAuthUser } from '../controllers/get-free-trial-eligibility-for-auth-user';

export function useFreeTrialEligibilityForAuthUser(): {
  eligible: boolean;
  isLoading: boolean;
  status: 'idle' | 'loading' | 'success' | 'error';
} {
  const clientReady = useClientRender();
  const { userIsLoading, userId, jwt } = useUser();
  const isForceEnabled =
    publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' &&
    clientReady &&
    getEnabledFeatureFlagsCookie().includes(FREE_TRIAL_FEATURE_FLAG_KEY);

  const {
    data: eligible,
    isLoading,
    status,
  } = useQuery(
    ['free-trial-eligibility', userId, jwt],
    async () => {
      if (userId && jwt) {
        return getFreeTrialEligibilityForAuthUser({
          userId,
          jwt,
        });
      }
    },
    {
      enabled: clientReady && !userIsLoading && Boolean(userId) && Boolean(jwt),
    }
  );

  if (isForceEnabled) {
    return { eligible: true, isLoading: false, status: 'success' };
  }

  return { eligible: eligible === true, isLoading, status };
}
