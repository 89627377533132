export function matcher(target: string, patterns: string[], options: { enableRegexpMatching?: boolean | null }) {
  return patterns.some((pattern) => {
    // Note: we need to wrap this in a try/catch because the pattern might not be a valid regex.
    try {
      return Boolean(options?.enableRegexpMatching) ? new RegExp(pattern).test(target) : target.includes(pattern);
    } catch {
      return false;
    }
  });
}
