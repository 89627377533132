import { DEFAULT_SORT } from '~/features/catalog/models/filter';

export const INITIAL_CATALOG_PAGE_PARAMS = {
  scope: 'all',
  price: 'any-price',
  school: 'any-school',
  skill: 'any-skill',
  difficulty: 'any-difficulty',
  duration: 'any-duration',
  type: 'any-type',
  sort: DEFAULT_SORT,
  page: 'page-1',
} as const;

export const BASE_CATALOG_PARAMS_PATH = `${INITIAL_CATALOG_PAGE_PARAMS.scope}/${INITIAL_CATALOG_PAGE_PARAMS.price}/${INITIAL_CATALOG_PAGE_PARAMS.school}/${INITIAL_CATALOG_PAGE_PARAMS.skill}/${INITIAL_CATALOG_PAGE_PARAMS.difficulty}/${INITIAL_CATALOG_PAGE_PARAMS.duration}/${INITIAL_CATALOG_PAGE_PARAMS.type}/${INITIAL_CATALOG_PAGE_PARAMS.sort}/${INITIAL_CATALOG_PAGE_PARAMS.page}`;

export const DEFAULT_CATALOG_PATH = `/catalog`;
export const ENROLLMENTS_CATALOG_PATH = `/catalog/enrolled/${INITIAL_CATALOG_PAGE_PARAMS.price}/${INITIAL_CATALOG_PAGE_PARAMS.school}/${INITIAL_CATALOG_PAGE_PARAMS.skill}/${INITIAL_CATALOG_PAGE_PARAMS.difficulty}/${INITIAL_CATALOG_PAGE_PARAMS.duration}/${INITIAL_CATALOG_PAGE_PARAMS.type}/${INITIAL_CATALOG_PAGE_PARAMS.sort}/${INITIAL_CATALOG_PAGE_PARAMS.page}`;
export const SEARCH_RESULTS_PATH = `/catalog/${INITIAL_CATALOG_PAGE_PARAMS.scope}/${INITIAL_CATALOG_PAGE_PARAMS.price}/${INITIAL_CATALOG_PAGE_PARAMS.school}/${INITIAL_CATALOG_PAGE_PARAMS.skill}/${INITIAL_CATALOG_PAGE_PARAMS.difficulty}/${INITIAL_CATALOG_PAGE_PARAMS.duration}/${INITIAL_CATALOG_PAGE_PARAMS.type}/relevance/${INITIAL_CATALOG_PAGE_PARAMS.page}`;
