import rehypeExternalLinks from 'rehype-external-links';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

import type { SimpleMarkdownProps } from '@udacity/ureact-markdown/simple-markdown';
import { SimpleMarkdown } from '@udacity/ureact-markdown/simple-markdown';

type MarkdownProps = Pick<SimpleMarkdownProps, 'rehypePlugins' | 'components'> & {
  markdownText: string;
};

const sanitizationSchema = {
  ...defaultSchema,
  attributes: {
    ...defaultSchema.attributes,
    a: [...(defaultSchema?.attributes?.['a'] ?? []), 'target', 'rel'],
  },
};

export function Markdown({ markdownText, rehypePlugins, components }: MarkdownProps) {
  return (
    <SimpleMarkdown
      components={components}
      markdownText={markdownText}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[
        [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
        ...(rehypePlugins ?? []), // Add plugins before sanitization
        [rehypeSanitize, sanitizationSchema],
      ]}
    />
  );
}
