import Image from 'next/image';

import { Link, List, ListItem } from '@chakra-ui/react';

import { socialMediaLinks } from '../data';

export function SocialMedia() {
  return (
    <List display='flex' gap='8px'>
      {socialMediaLinks.map((link) => (
        <ListItem key={link.id}>
          <Link href={link.href}>
            <Image alt={link.label} height={32} src={link.iconSrc} width={32} />
          </Link>
        </ListItem>
      ))}
    </List>
  );
}
