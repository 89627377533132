import { pickBy } from 'lodash-es';
import { stringify } from 'querystring';

import { logger } from '~/features/logging/logger';

import { sanitizedPromoSchema } from '../models/promotions';

export async function getPromotion({
  pathname,
  promo,
  promoParticipation,
  forcePromo,
  isFreeTrialEnabled,
}: {
  pathname: string;
  promo?: string;
  promoParticipation?: string;
  forcePromo?: string;
  isFreeTrialEnabled?: boolean;
}) {
  try {
    const searchParams = stringify(
      pickBy(
        {
          pathname,
          promo,
          promo_participation: promoParticipation,
          force_promo: forcePromo,
          is_free_trial_enabled: isFreeTrialEnabled,
        },
        (value) => typeof value !== 'undefined'
      )
    );
    const response = await fetch(`/api/get-promotion?${searchParams}`, {
      credentials: 'include',
    });
    const data = sanitizedPromoSchema.parse(await response.json());
    return data;
  } catch (error) {
    logger.error('Failed to fetch a promotion.', {
      file: 'src/features/promotions/controllers/get-promotion.ts',
      function: 'getPromotion',
      pathname,
    });
    return null;
  }
}
