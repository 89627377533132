import auth from '@udacity/ureact-hoth';

import { publicEnv } from '~/features/environment/public';
import { logger } from '~/features/logging/logger';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { Favorites, favoritesSchema } from '../models/favorite';

export async function getFavorites(userId: string): Promise<Favorites> {
  const jwt = auth.getJWT();

  if (!userId || !jwt) {
    logger.fatal('User is not authenticated', {
      file: 'src/features/favorites/controllers/get-favorites.ts',
      function: 'getFavorites',
      error: {
        userId,
      },
    });

    throw new Error('User is not authenticated');
  }

  return await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_RECOMMENDATIONS_URL}/favorites?userId=${userId}`,
    method: 'GET',
    zodSchema: favoritesSchema,
    authorization: `Bearer ${jwt}`,
  });
}
