import { FormEvent, useCallback } from 'react';

import { useToast } from '@chakra-ui/react';

import { identifyEvent } from '~/features/analytics/identify-event';
import { trackEvent } from '~/features/analytics/track-event';
import { pushDataLayer } from '~/features/analytics/utils/data-layer';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { useUser } from '~/features/user/hooks/use-user';

export function useNewsletterSignUpHandler({ showToast = true }: { showToast?: boolean } = {}) {
  const { user } = useUser();
  const toast = useToast();

  const handleNewsletterSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const form = event.target as HTMLFormElement;
      const emailInput = form.elements.namedItem('email') as HTMLInputElement;

      const formId = 'Newsletter Signup';

      pushDataLayer({ event: 'emailSubmitted' });

      const userTraits = {
        userIdentifier: user?.id ? user.id : getAnonymousIdCookie()!,
        email: emailInput.value,
        ...(user && { name: user.firstName }),
      };

      // Properties that are shared between identify and track events.
      const sharedProperties = { formId };

      if (userTraits.userIdentifier) {
        identifyEvent(
          userTraits.userIdentifier,
          {
            LeadSource: 'Website',
            LeadWebsiteUrl: window.location.href,
            ...sharedProperties,
            ...userTraits,
          },
          { sendToSalesforce: true },
          () => {
            trackEvent({
              type: 'submit',
              label: 'Lead Generation - HQ - Newsletter Signup',
              name: 'Marketing Qualified Lead Form Submitted',
              additionalAttributes: {
                gtm_activated: true,
                ...sharedProperties,
                ...userTraits,
              },
            });
          }
        );
      }

      if (showToast) {
        toast({
          title: 'Welcome aboard! 💌',
          description: 'Thank you for joining our newsletter. Get ready for exciting updates!',
          variant: 'success',
        });
      }

      form.reset();
    },
    [toast, user, showToast]
  );

  return handleNewsletterSubmit;
}
