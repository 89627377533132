import { z } from 'zod';

import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

/**
 * This function is used to check if user has already used a free trial or not.
 */
export async function getFreeTrialEligibilityForAuthUser({
  jwt,
  userId,
}: {
  jwt: string;
  userId: string;
}): Promise<boolean> {
  return await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_BRAAVOS_URL}/users/${userId}`,
    zodSchema: z
      .object({
        eligible_for_trial: z.boolean().optional(),
      })
      .transform((res) => Boolean(res.eligible_for_trial)),
    authorization: `Bearer ${jwt}`,
    method: 'GET',
  });
}
