import { useQuery } from 'react-query';

import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { getEnabledFeatureFlagsCookie } from '~/features/cookies/utils/get-enabled-feature-flag-cookie';
import { getHasFocusedCookie } from '~/features/cookies/utils/get-has-focused-cookie';
import { publicEnv } from '~/features/environment/public';
import { getViewerCountryCode } from '~/features/internationalization/utils/get-viewer-geo-codes';
import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';

import { FREE_TRIAL_FEATURE_FLAG_KEY } from '../constants';
import { getFreeTrialFeatureFlag } from '../controllers/get-free-trial-feature-flag';

export function useFreeTrialFeatureFlag(): {
  enabled: boolean;
  isLoading: boolean;
  status: 'idle' | 'loading' | 'success' | 'error';
} {
  const clientReady = useClientRender();
  const { userIsLoading, userId } = useUser();

  const anonymousId = getAnonymousIdCookie();
  const countryCode = getViewerCountryCode();
  const hasActiveSubscription = getActiveSubscriptionCookie();
  const hasFocused = getHasFocusedCookie();

  const isForceEnabled =
    publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' &&
    clientReady &&
    getEnabledFeatureFlagsCookie().includes(FREE_TRIAL_FEATURE_FLAG_KEY);

  const {
    data: enabled,
    isLoading,
    status,
  } = useQuery(
    ['free-trial-feature-flag', userId, anonymousId, countryCode, hasActiveSubscription, hasFocused],
    async () => {
      const userKey = userId || anonymousId;
      if (userKey && countryCode) {
        return getFreeTrialFeatureFlag({
          userKey,
          countryCode,
          hasActiveSubscription: hasActiveSubscription === true,
          hasFocused: hasFocused === true,
        });
      }
    },
    {
      enabled: clientReady && Boolean(countryCode) && Boolean(anonymousId) && !userIsLoading,
    }
  );

  if (isForceEnabled) {
    return { enabled: true, isLoading: false, status: 'success' };
  }

  return { enabled: enabled === true, isLoading, status };
}
