import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import NextLink, { LinkProps } from 'next/link';

import { Button, ButtonProps } from '@chakra-ui/react';

export type ButtonLinkProps = PropsWithChildren<{
  linkProps: LinkProps;
  buttonProps?: ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>;
  isExternal?: boolean;
}>;

export function ButtonLink({ linkProps, buttonProps, isExternal, children }: ButtonLinkProps) {
  if (buttonProps?.isDisabled) {
    return <Button {...buttonProps}>{children}</Button>;
  }

  if (isExternal) {
    return (
      <Button as='a' {...buttonProps} href={linkProps.href} rel='noopener noreferrer' target='_blank'>
        {children}
      </Button>
    );
  }

  return (
    <NextLink {...linkProps} legacyBehavior passHref>
      <Button as='a' {...buttonProps}>
        {children}
      </Button>
    </NextLink>
  );
}
