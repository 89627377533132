import Image from 'next/image';

import { Box, Flex } from '@chakra-ui/react';

import { ContentContainer } from '~/components/container';
import { Link } from '~/components/link';
import { useBaseUrl } from '~/hooks/use-base-url';

import { unauthenticatedNavigation } from '../data';

import { Legal } from './legal';
import { Navigation } from './navigation';
import { NewsletterSignUp } from './newsletter';
import { SocialMedia } from './social-media';

export function PageFooter() {
  const baseUrl = useBaseUrl();

  return (
    <Box bgColor='white' borderTop='solid 1px' borderTopColor='gray.300'>
      <ContentContainer>
        <Box as='footer' py='64px'>
          <Link aria-label='Link to home page' display='flex' href={baseUrl} locale={false} width='fit-content'>
            <Image alt='Udacity Accenture logo' height={32} src='/images/udacity-accenture-logo.svg' width={170} />
          </Link>

          <Box h={{ base: '24px', xl: '40px' }} />

          <Navigation navigation={unauthenticatedNavigation} />

          <Box h='40px' />

          <Flex
            alignItems={{ base: 'flex-start', md: 'flex-end' }}
            flexDirection={{ base: 'column', md: 'row' }}
            gap={{ base: '40px', md: '16px' }}
            justifyContent='space-between'
          >
            <NewsletterSignUp />
            <SocialMedia />
          </Flex>
          {/* <CollapsableNavigation /> */}
          <Legal />
        </Box>
      </ContentContainer>
    </Box>
  );
}
