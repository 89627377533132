import { BaseSyntheticEvent, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Box,
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { ContentContainer } from '~/components/container';
import { Link } from '~/components/link';
import { trackEvent } from '~/features/analytics/track-event';

import { COOKIE_POLICY_LINK, PRIVACY_POLICY_LINK } from '../constants';

export function CookieConsentBanner() {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const openRef = useRef(true);

  function handleSave(
    event: BaseSyntheticEvent<object, any, any>,
    userOptions: { Advertising: boolean; Analytics: boolean }
  ) {
    // TODO: confirm analytics taxonomy
    trackEvent({
      name: 'ConsentClicked',
      type: 'click',
      label: 'I accept',
      action: 'accept clicked',
      additionalAttributes: {
        ...userOptions,
      },
    });

    if (isModalOpen) {
      onModalClose();
    }

    onDrawerClose();

    // Note: See https://docs.transcend.io/docs/consent/configuration/creating-your-own-ui#consent-authorization for why we pass down the native event down.
    window.airgap?.setConsent(event.nativeEvent, { ...userOptions, Functional: true });
  }

  function handleClickMoreOptions() {
    onModalOpen();
    onDrawerClose();
  }

  useEffect(() => {
    function openConsentDrawerHandler() {
      window.airgap?.ready(() => {
        const confirmed = window.airgap?.getConsent().confirmed;
        const isGDPR = window.airgap?.getRegimes().has('GDPR');
        if (!confirmed && isGDPR) {
          openRef.current = false;
          onDrawerOpen();
        }
      });
    }
    if (!isDrawerOpen && openRef.current) {
      for (const event of ['scroll', 'mousemove']) {
        window.addEventListener(event, openConsentDrawerHandler);
      }
      return () => {
        for (const event of ['scroll', 'mousemove']) {
          window.removeEventListener(event, openConsentDrawerHandler);
        }
      };
    }
  }, [onDrawerOpen, isDrawerOpen]);

  return (
    <>
      <Drawer
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        data-testid='cookie-consent-banner'
        isOpen={isDrawerOpen}
        placement='bottom'
        variant='alwaysOpen'
        onClose={onDrawerClose}
      >
        <DrawerContent
          backgroundColor='accent.sky.100'
          borderRadius='base'
          boxShadow='4px 0px 10px 2px rgba(11, 11, 11, 0.15);'
          padding={{ base: '24px 0px', xl: '36px 0px 32px' }}
        >
          <ContentContainer position='relative'>
            <DrawerCloseButton top='0px' />
            <DrawerHeader padding='0px'>
              <Heading as='h2' marginBottom='28px' size='h5'>
                We value your privacy
              </Heading>
            </DrawerHeader>
            <DrawerBody padding='0px'>
              <Text marginBottom='32px'>
                We and our partners use technology such as cookies on our site to personalize content and ads, provide
                social media features, and analyze our traffic. Click “I accept” to consent to the use of this
                technology on our site. Click “More Options” for more information on how we use cookies. You can change
                your mind and change your consent choices at any time by returning to this site.
              </Text>
            </DrawerBody>
            <DrawerFooter flexWrap='wrap' gap='24px' justifyContent='space-between' padding='0px'>
              <Flex flexWrap='wrap' gap={{ base: '12px', md: '24px' }}>
                <Button
                  onClick={(e) =>
                    handleSave(e, {
                      Advertising: true,
                      Analytics: true,
                    })
                  }
                >
                  I Accept
                </Button>
                <Button variant='outline' onClick={handleClickMoreOptions}>
                  More Options
                </Button>
              </Flex>
              <Flex flexWrap='wrap' gap={{ base: '12px', md: '24px' }}>
                <Link href={PRIVACY_POLICY_LINK} isExternal>
                  Privacy Policy
                </Link>
                <Link href={COOKIE_POLICY_LINK} isExternal>
                  Cookie Policy
                </Link>
              </Flex>
            </DrawerFooter>
          </ContentContainer>
        </DrawerContent>
      </Drawer>
      <CookieManagerModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        onSave={handleSave}
        onCancel={() => {
          onDrawerOpen();
          onModalClose();
        }}
      />
    </>
  );
}

const cookieManagerFormDataSchema = z.object({
  Advertising: z.boolean(),
  Analytics: z.boolean(),
});

type FormData = z.infer<typeof cookieManagerFormDataSchema>;

function CookieManagerModal({
  isOpen,
  onClose,
  onCancel,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSave: (
    event: BaseSyntheticEvent<object, any, any>,
    userOptions: { Advertising: boolean; Analytics: boolean }
  ) => void;
}) {
  const { register, handleSubmit } = useForm<FormData>({
    resolver: zodResolver(cookieManagerFormDataSchema),
    defaultValues: {
      Advertising: false,
      Analytics: false,
    },
  });

  function handleFormSubmit(formData: FormData, event?: BaseSyntheticEvent<object, any, any>) {
    onSave(event!, formData);
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      size='3xl'
      isCentered
      onClose={onClose}
      onOverlayClick={onCancel}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as='h2' fontWeight='400' size='1'>
            Cookie Choices
          </Heading>
        </ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody>
          <Text marginBottom='24px'>
            Please select whether this site may use Analytics and/or Advertising cookies as described below. To learn
            more about the cookies we use, view our{' '}
            <Link href={PRIVACY_POLICY_LINK} isExternal>
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link href={COOKIE_POLICY_LINK} isExternal>
              Cookie Policy
            </Link>
            .
          </Text>
          <chakra.form
            display='flex'
            flexDirection='column'
            gap='40px'
            id='airgap-cookie-form'
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <FormControl alignItems='center' display='flex' gap='24px'>
              <Box flex='0 1 400px'>
                <FormLabel display='block' mb='0'>
                  Required Cookies
                </FormLabel>
                <Text id='airgap-required-cookies-description'>
                  These cookies are required to enable core site functionality, and cannot be turned off.
                </Text>
              </Box>
              <Flex alignSelf='flex-end' flex='1'>
                <Switch aria-describedby='airgap-required-cookies-description' marginLeft='auto' isChecked isDisabled />
              </Flex>
            </FormControl>

            <FormControl alignItems='center' display='flex' gap='24px'>
              <Box flex='0 1 400px'>
                <FormLabel display='block' mb='0'>
                  Analytics Cookies
                </FormLabel>
                <Text id='airgap-analytics-cookies-description'>
                  These cookies allow us to analyze site usage so we can measure and improve performance.
                </Text>
              </Box>
              <Flex alignSelf='flex-end' flex='1'>
                <Switch
                  aria-describedby='airgap-analytics-cookies-description'
                  marginLeft='auto'
                  {...register('Analytics')}
                />
              </Flex>
            </FormControl>

            <FormControl alignItems='center' display='flex' gap='24px'>
              <Box flex='0 1 400px'>
                <FormLabel display='block' mb='0'>
                  Advertising Cookies
                </FormLabel>
                <Text id='airgap-advertising-cookies-description'>
                  These cookies are used by advertising companies to serve ads that are relevant to your interests.
                </Text>
              </Box>
              <Flex alignSelf='flex-end' flex='1'>
                <Switch
                  aria-describedby='airgap-advertising-cookies-description'
                  marginLeft='auto'
                  {...register('Advertising')}
                />
              </Flex>
            </FormControl>
          </chakra.form>
        </ModalBody>
        <ModalFooter gap='24px'>
          <Button form='airgap-cookie-form' type='submit'>
            Accept My Choices
          </Button>
          <Button aria-label='Cancel cookie options modal' variant='outline' onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
