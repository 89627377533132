import { PropsWithChildren } from 'react';

import { BoxProps, Container } from '@chakra-ui/react';

export function ContentContainer(props: PropsWithChildren<BoxProps>) {
  const { children, ...boxProps } = props;
  return (
    <Container
      margin='auto'
      maxW={{ base: '100%', xl: 'content-max-width' }}
      paddingEnd={{ base: '16px', md: '24px', lg: '48px', xl: '0' }}
      paddingStart={{ base: '16px', md: '24px', lg: '48px', xl: '0' }}
      {...boxProps}
    >
      {children}
    </Container>
  );
}
