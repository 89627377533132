import { useEffect, useState } from 'react';

export function useBaseUrl() {
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(new URL(window.location.href).origin);
  }, []);

  return baseUrl;
}
