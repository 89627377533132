import { PropsWithChildren, ReactNode } from 'react';

import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

export type ModalProps = PropsWithChildren<
  ChakraModalProps & {
    title: string;
    footerButtons?: ReactNode;
  }
>;

export function Modal({ title, footerButtons, children, ...rest }: ModalProps) {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {footerButtons && <ModalFooter gap={6}>{footerButtons}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
}
