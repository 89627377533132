import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import { useFreeTrialState } from '~/features/free-trial/hooks/use-free-trial-state';

import { getPromotion } from '../controllers/get-promotion';
import { singleQueryParamValueSchema } from '../models/promotions';

export function usePromotion() {
  const { asPath, query, isReady } = useRouter();
  const promo = singleQueryParamValueSchema.parse(query.promo);
  const promoParticipation = singleQueryParamValueSchema.parse(query.promo_participation);
  const forcePromo = singleQueryParamValueSchema.parse(query.force_promo);
  const pathname = asPath?.replace(/(\?.+$|#.+$)/, ''); // Note: we want to remove the query params and any hashes from the pathname to prevent refetching if these change.
  const freeTrial = useFreeTrialState();
  const isFreeTrialEnabled = freeTrial.status === 'on';

  return useQuery(
    ['promotions', pathname, promo, promoParticipation, forcePromo, isFreeTrialEnabled],
    async () => {
      return getPromotion({
        pathname,
        promo,
        promoParticipation,
        forcePromo,
        isFreeTrialEnabled,
      });
    },
    {
      enabled: isReady && freeTrial.status !== 'loading',
    }
  );
}
