import { useQuery } from 'react-query';

import auth from '@udacity/ureact-hoth';

import { getUserExperience } from '../controllers/get-user-experience';

export function useUserExperience(props: { disabled: boolean } | void) {
  const jwt = auth.getJWT();

  const {
    data: userExperience,
    error: userExperienceError,
    isLoading: userExperienceIsLoading,
  } = useQuery('userExperience', () => getUserExperience(jwt!), {
    enabled: Boolean(jwt) && !props?.disabled,
  });

  return {
    userExperience,
    userExperienceError,
    userExperienceIsLoading,
  };
}
