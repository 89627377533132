import { useQuery } from 'react-query';

import auth from '@udacity/ureact-hoth';

import { userId } from '~/features/user/utils/get-user-id';

import { getEnrollmentsProgress } from '../controllers/get-enrollments-progress';

export function useEnrollmentsProgress() {
  const jwt = auth.getJWT();

  const {
    data: enrollmentsProgress,
    error: enrollmentsProgressError,
    isLoading: enrollmentsProgressIsLoading,
    isFetched: enrollmentsProgressIsFetched,
  } = useQuery('enrollment-progress', () => getEnrollmentsProgress(jwt!), {
    enabled: Boolean(userId) && Boolean(jwt),
  });

  return {
    enrollmentsProgress,
    enrollmentsProgressError,
    enrollmentsProgressIsLoading,
    enrollmentsProgressIsFetched,
  };
}
