import { publicEnv } from '~/features/environment/public';
import { logger } from '~/features/logging/logger';
import { getUserIdFromJwt } from '~/features/user/utils/get-user-id-from-jwt';
import { queryUdacityService } from '~/utils/query-udacity-service';

import {
  EnrollmentsProgress,
  enrollmentsProgressQuery,
  enrollmentsProgressSchema,
} from '../models/enrollments-progress';

export async function getEnrollmentsProgress(jwt: string): Promise<EnrollmentsProgress> {
  const userId = getUserIdFromJwt(jwt);

  if (!userId) {
    logger.fatal('Provided JWT does not contain a user ID', {
      file: 'src/features/program/controllers/get-enrollments-progress.ts',
      function: 'getEnrollmentsProgress',
    });

    throw new Error('Provided JWT does not contain a user ID');
  }

  return await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_PROGRESS_URL,
    zodSchema: enrollmentsProgressSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: enrollmentsProgressQuery,
      variables: { uid: userId },
    }),
  });
}
