import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { companyDataResponseSchema, EnterpriseCompanyData, fetchCompanyData } from '../models/enterprise-company-data';

/**
 * Fetches the company name and logo for a single enterprise company by its slug.
 */
export async function getEnterpriseCompanyData(companySlug: string, jwt: string): Promise<EnterpriseCompanyData> {
  const result = await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_EMC_CONTENT_URL + '/gql/query/public',
    zodSchema: companyDataResponseSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: fetchCompanyData,
      variables: { companySlug },
    }),
  });

  return result.data.companyBySlug;
}
