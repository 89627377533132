import { useEffect, useRef } from 'react';

import { Alert, Box, CloseButton, Fade, Flex } from '@chakra-ui/react';
import { InfoIcon, WarningIcon } from '@udacity/chakra-uds-icons';

import { Markdown } from '~/components/markdown';
import { trackEvent } from '~/features/analytics/track-event';

export type BrowBannerProps = {
  open: boolean;
  text?: string;
  isCloseable?: boolean;
  onClose?: () => void;
  type?: 'marketing' | 'info' | 'error' | null;
  promotionId?: string;
};

export function BrowBanner({ open, text, onClose, isCloseable, type, promotionId }: BrowBannerProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;
    function clickHandler(e: MouseEvent) {
      const target = e.target as HTMLAnchorElement;
      trackEvent({
        type: 'click',
        label: target.innerText,
        value: promotionId,
        name: 'Website Banner Link Clicked',
      });
    }
    if (node) {
      const linkNodes = node.querySelectorAll('a[href]') as NodeListOf<HTMLAnchorElement>;
      for (const linkNode of linkNodes) {
        linkNode.addEventListener('click', clickHandler);
      }
      return () => {
        for (const linkNode of linkNodes) {
          linkNode.removeEventListener('click', clickHandler);
        }
      };
    }
  }, [promotionId]);

  return (
    <Fade in={open} unmountOnExit>
      <Alert
        alignItems='center'
        bgGradient='linear(135deg, blue.500, purple.500)'
        border='none'
        borderRadius='none'
        boxShadow='0px 1px 2px 0px rgba(11, 11, 11, 0.06), 0px 1px 3px 0px rgba(11, 11, 11, 0.10)'
        className='brow-banner'
        color='white'
        justifyContent='center'
        padding='8px'
        size='sm'
        status='success'
        {...(type === 'info' && {
          bgGradient: 'unset',
          bg: 'blue.100',
          color: 'black',
        })}
        {...(type === 'error' && {
          bgGradient: 'unset',
          bg: 'red.100',
          color: 'black',
        })}
      >
        <Flex
          alignItems='center'
          flex='0 1 1080px'
          justifyContent='center'
          textAlign='center'
          sx={{
            a: {
              color: type === 'marketing' ? 'white' : 'black',
            },
            ['a:hover,a:focus,a:visited']: {
              color: type === 'marketing' ? 'white' : 'black',
            },
            'strong,b': {
              fontWeight: '700',
            },
          }}
        >
          {type === 'info' && <InfoIcon color='blue.700' height='24px' marginRight='4px' width='24px' />}
          {type === 'error' && <WarningIcon color='red.600' height='24px' marginRight='4px' width='24px' />}
          {text && (
            <Box ref={ref}>
              <Markdown markdownText={text} />
            </Box>
          )}
        </Flex>
        {isCloseable && (
          <CloseButton
            alignSelf='flex-start'
            color={type === 'marketing' ? 'white' : 'black'}
            position='relative'
            right={-1}
            top={-1}
            onClick={onClose}
          />
        )}
      </Alert>
    </Fade>
  );
}
