import { PropsWithChildren } from 'react';

import { Alert as ChakraAlert, AlertDescription, Box, BoxProps, CloseButton } from '@chakra-ui/react';
import { CancelIcon, CheckedIcon, InfoIcon, WarningIcon } from '@udacity/chakra-uds-icons';

export type AlertStatus = 'success' | 'error' | 'warning' | 'info';
export type AlertMessage = {
  message: string;
  level: AlertStatus;
};

export function Alert({
  status,
  closeable,
  children,
  onClose,
  ...rest
}: PropsWithChildren<{ status?: AlertStatus; closeable?: boolean; onClose?: () => void } & BoxProps>) {
  return (
    <ChakraAlert status={status} {...rest}>
      <Box className='custom-icon'>{iconMap.get(status)}</Box>
      <AlertDescription>{children}</AlertDescription>
      {closeable && <CloseButton onClick={onClose} />}
    </ChakraAlert>
  );
}

const iconMap = new Map();
iconMap.set('success', <CheckedIcon h='32px' mx='4px' w='32px' />);
iconMap.set('error', <CancelIcon h='32px' mx='4px' w='32px' />);
iconMap.set('warning', <WarningIcon h='32px' mx='4px' w='32px' />);
iconMap.set('info', <InfoIcon h='32px' mx='4px' w='32px' />);
