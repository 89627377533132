import { trackEvent } from '../analytics/track-event';

import { NavGroup } from './types';

export function flattenNavGroups(navGroups: NavGroup[]) {
  const flattenedNavGroups: NavGroup[] = [];

  navGroups.forEach((navGroup) => {
    flattenedNavGroups.push(navGroup);

    if (navGroup.nestedNavGroups) {
      flattenedNavGroups.push(...flattenNavGroups(navGroup.nestedNavGroups));
    }
  });

  return flattenedNavGroups;
}

export function trackFooterLinkClick(linkLabel: string, groupTitle: string) {
  trackEvent({
    name: 'Footer Link Clicked',
    type: 'click',
    label: groupTitle,
    value: linkLabel,
  });
}
