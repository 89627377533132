import { PropsWithChildren, ReactNode } from 'react';

import { Box, Flex, FlexProps } from '@chakra-ui/react';

import { publicEnv } from '~/features/environment/public';
import { PageHeader } from '~/features/page-header/components/page-header';
import { CookieConsentBanner } from '~/features/privacy/components/cookie-consent-banner';
import { PromotionBanner } from '~/features/promotions/components/promotion-banner';
import { useUserExperience } from '~/features/user/hooks/use-user-experience';
import { useClientRender } from '~/hooks/use-client-render';

import { PageFooter } from '../../page-footer/components/page-footer';

export function PageLayout({
  withoutVerticalPadding,
  isSimpleMode,
  children,
  bannerSlot = <PromotionBanner />,
  ...flexProps
}: PropsWithChildren<
  { withoutVerticalPadding?: boolean; isSimpleMode?: boolean; bannerSlot?: ReactNode } & FlexProps
>) {
  const clientReady = useClientRender();
  const { userExperience } = useUserExperience();

  return (
    <Flex flexDirection='column' height='100%' {...flexProps}>
      <PageHeader isSimpleMode={isSimpleMode} />
      {publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'test' && clientReady && <>{bannerSlot}</>}
      <Box as='main' flex={1} py={withoutVerticalPadding ? '' : { base: '24px', md: '40px' }}>
        {children}
      </Box>
      {!isSimpleMode && !userExperience?.hasFocused && (
        <>
          <Box h='64px' />
          <PageFooter />
        </>
      )}
      {clientReady && <CookieConsentBanner />}
    </Flex>
  );
}
