import { z } from 'zod';

import { allowBlockListFragment, allowBlockListSchema } from '~/features/allow-block-list/model';
import { gql } from '~/utils/gql';

import { countdownConfigSchema } from './countdown-config';

export const promotionTypeSchema = z.union([
  z.literal('personalized-discount'),
  z.literal('standard-promotion'),
  z.literal('announcement'),
]);
export type PromotionType = z.infer<typeof promotionTypeSchema>;

export const priceCardsBannerSchema = z.object({
  text: z.string(),
  linkLabel: z.string().nullable(),
  linkUrl: z.string().nullable(),
});

export const stickerSchema = z.object({
  text: z.string(),
  linkLabel: z.string().nullable(),
  linkUrl: z.string().nullable(),
});

export const bannerSchema = z.object({
  _id: z.string(),
  text: z.string(),
  routeRestrictions: allowBlockListSchema.nullable(),
  dismissalCookieKey: z.string().nullable(),
  isCloseable: z.boolean(),
  type: z.union([z.literal('marketing'), z.literal('info')]).nullable(),
});

export const promotionSchema = z.object({
  _id: z.string(),
  promotionId: z.string(),
  type: promotionTypeSchema,
  isActive: z.boolean(),
  priority: z.number(),
  banner: bannerSchema.nullable(),
  heroSticker: stickerSchema.nullable(),
  priceCardsBanner: priceCardsBannerSchema.nullable(),
  startDateTime: z.string({ description: 'Stored in Sanity as UTC.' }),
  endDateTime: z.string({ description: 'Stored in Sanity as UTC.' }),
  audienceRestrictions: z
    .union([
      z.literal('all'),
      z.literal('non-subscribed'),
      z.literal('subscribed'),
      z.literal('free-access'),
      z.literal('focus-users'),
      z.literal('free-trial-eligible-users'),
    ])
    .nullable(),
  geoRestrictions: allowBlockListSchema.nullable(),
  currencyRestrictions: allowBlockListSchema.nullable(),
  routeRestrictions: allowBlockListSchema.nullable(),
  promoCode: z.string().nullable(),
  skipPromoWhenCouponCookied: z.boolean().nullable(),
  countdownConfig: countdownConfigSchema.nullable(),
});

export const promotionsSchema = z
  .object({
    data: z.object({
      allPromotion: z.array(promotionSchema),
    }),
  })
  .transform(({ data }) => data.allPromotion);

export type PromotionsResponse = z.input<typeof promotionsSchema>;
export type PromotionsList = z.infer<typeof promotionsSchema>;
export type Promotion = PromotionsList[number];

export const fetchPromotionsQuery = gql`
  query FetchActivePromotions($where: PromotionFilter) {
    allPromotion(where: $where, sort: { priority: DESC }) {
      _id
      type
      promotionId
      isActive
      banner {
        _id
        type
        text
        dismissalCookieKey
        isCloseable
        routeRestrictions {
          ...allowBlockList
        }
      }
      heroSticker {
        text
        linkLabel
        linkUrl
      }
      priceCardsBanner {
        text
        linkLabel
        linkUrl
      }
      startDateTime
      endDateTime
      audienceRestrictions
      routeRestrictions {
        ...allowBlockList
      }
      currencyRestrictions {
        ...allowBlockList
      }
      geoRestrictions {
        ...allowBlockList
      }
      priority
      skipPromoWhenCouponCookied
      promoCode
      countdownConfig {
        countdownTo
        buttonLabelOverride
        blurb
      }
    }
  }
  ${allowBlockListFragment}
`;

export const singleQueryParamValueSchema = z
  .union([z.string(), z.array(z.string())])
  .optional()
  .transform((value) => (Array.isArray(value) ? value[0] : value));

// Note: these are the promotion fields that we want to show publicly.
export const sanitizedPromoSchema = z
  .object({
    _id: z.string(),
    banner: bannerSchema.nullable(),
    heroSticker: stickerSchema.nullable(),
    priceCardsBanner: priceCardsBannerSchema.nullable(),
    isActive: z.boolean(),
    type: promotionTypeSchema,
    startDateTime: z.string(),
    endDateTime: z.string(),
    promotionId: z.string(),
    countdownConfig: countdownConfigSchema.nullable(),
    promoCode: z.string().nullable(),
  })
  .nullable();

export type SanitizedPromotion = z.infer<typeof sanitizedPromoSchema>;
