import auth from '@udacity/ureact-hoth';

import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import {
  ActiveConnectCohortCountsResponse,
  activeConnectCohortCountsResponseSchema,
  fetchActiveConnectCohortCountQuery,
} from '../models/connect-cohorts';

export const MEETINGS_PAGE_LIMIT = 6;

export async function getIsConnectLearner(): Promise<boolean> {
  const jwt = auth.getJWT();
  const userKey = auth.getCurrentUserId();

  if (!userKey) return Promise.resolve(false);

  const result: ActiveConnectCohortCountsResponse = await queryUdacityService({
    url: `${publicEnv.NEXT_PUBLIC_CONNECT_URL}/graphql`,
    zodSchema: activeConnectCohortCountsResponseSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: fetchActiveConnectCohortCountQuery,
      variables: {
        userKey,
      },
    }),
  });

  return (result?.data?.myConnectData?.myCohorts?.totalCount || 0) > 0;
}
