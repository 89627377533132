import { z } from 'zod';

import { gql } from '~/utils/gql';

export const enrollmentsProgressSchema = z
  .object({
    data: z.object({
      user: z.object({
        completed: z.object({
          concepts: z.number(),
          lessons: z.number(),
          quizzes: z.number(),
          projects: z.number(),
          labs: z.number(),
          programs: z.number(),
        }),
        programs: z
          .array(
            z.object({
              key: z.string(),
              last_active: z.string().nullable(),
              complete: z.boolean(),
              completion: z.number(),
            })
          )
          .nullable(),
      }),
    }),
  })
  .transform(({ data }) => {
    return {
      completed: data.user.completed,
      programs: data.user.programs
        ? data.user.programs.map((program) => ({
            key: program.key,
            lastActive: program.last_active,
            complete: program.complete,
            completion: Math.round(program.completion),
          }))
        : [],
    };
  });

export type EnrollmentsProgress = z.infer<typeof enrollmentsProgressSchema>;
export type EnrollmentsProgressResponse = z.input<typeof enrollmentsProgressSchema>;

export const enrollmentsProgressQuery = gql`
  query FetchEnrollmentsProgress($uid: String!) {
    user(id: $uid) {
      id
      completed {
        concepts
        lessons
        quizzes
        projects
        labs
        programs
      }
      programs {
        key
        last_active
        complete
        completion
      }
    }
  }
`;
