import Link from 'next/link';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { InfoIcon } from '@udacity/chakra-uds-icons';

import { useNewsletterSignUpHandler } from '~/hooks/use-newsletter-signup';

export function NewsletterSignUp() {
  const handleNewsletterSubmit = useNewsletterSignUpHandler();
  return (
    <Box as='form' maxWidth={{ base: '100%', md: '780px' }} width='100%' onSubmit={handleNewsletterSubmit}>
      <FormControl>
        <Flex flexDirection={{ base: 'column', md: 'row' }} gap={{ base: '16px', md: '8px' }}>
          <Flex flex={1} flexDirection='column' gap='8px'>
            <Flex justifyContent='space-between'>
              <FormLabel alignItems='center' display='flex'>
                Submit your email to stay updated on the latest in technology
              </FormLabel>
              <Popover placement='top'>
                <PopoverTrigger>
                  <IconButton
                    aria-label='Sign up Terms and Conditions.'
                    icon={<InfoIcon color='black' height='24px' width='24px' />}
                    paddingInline='0px'
                    size='xs'
                    variant='ghost'
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody fontWeight='500' mt='16px'>
                    By clicking &quot;Subscribe,&quot; you agree to our{' '}
                    <Box as={Link} href={`/legal/en-us/terms-of-use`} textDecoration='underline'>
                      Terms of Use
                    </Box>{' '}
                    and{' '}
                    <Box as={Link} href={'/legal/privacy'} textDecoration='underline'>
                      Privacy Policy
                    </Box>
                    .
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            <Input autoComplete='email' name='email' placeholder='Email Address' type='email' isRequired />
          </Flex>

          <Button alignSelf='flex-end' type='submit' variant='outline' width={{ base: '100%', md: 'auto' }}>
            Subscribe To Newsletter
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}
