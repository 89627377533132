import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useTranslation } from 'react-i18next';

import { Link as ChakraLink, LinkProps, StyleProps } from '@chakra-ui/react';
import { ArrowLeftSmallIcon, ArrowRightSmallIcon } from '@udacity/chakra-uds-icons';

import { publicEnv } from '~/features/environment/public';

export function Link(props: LinkProps & StyleProps & Omit<NextLinkProps, 'href'> & { withArrow?: boolean }) {
  const { i18n } = useTranslation();
  const languageDir = i18n.dir();

  const { withArrow, children, ...linkProps } = props;

  return (
    <ChakraLink
      as={NextLink}
      prefetch={publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'test' ? false : null}
      {...linkProps}
    >
      {children} {withArrow && languageDir === 'ltr' && <ArrowRightSmallIcon h='32px' w='32px' />}
      {withArrow && languageDir === 'rtl' && <ArrowLeftSmallIcon h='32px' w='32px' />}
    </ChakraLink>
  );
}
